import React from 'react'

export default function Footer() {
  return (
    <div className='crispy-footer'>
        <div className='footer-text-left'><h2>crispy todos</h2></div>
        <div className='footer-text-right'>
            <p>stay crispy</p>
            <p>keep er goin</p>
        </div>
    </div>
  )
}
